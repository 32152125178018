
import { defineComponent } from 'vue'

export default defineComponent({
  name: "CategoryForm",
  data() {
    return {
      statuses: [
        "Jual",
        "Sewa",
        "Juat/Sewa",
      ],
      query: {
        status: null,
      }
    }
  },
  methods: {
    async handleSubmit() {
      this.$emit('closestatus');
      this.$router.push({ 
        query: {
          ...this.$route.query,
          ...this.query
        } 
      });
      setTimeout(() => {
        window.location.reload();
      }, 10);
    },
    onQueryChanged() {
      // This function will be run whenever `query` changes.
      // You can put any code you want here.
      // this.$store.dispatch('module/updateFilterQuery', this.query);
    },
  },
  watch: {
    query: {
      handler: 'onQueryChanged',
      deep: true,
    }
  }
})
