import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.statuses, (status, index) => {
      return (_openBlock(), _createElementBlock("button", {
        class: "w-full p-2 hover:bg-gray-50",
        key: index,
        onClick: () => {
        _ctx.query.status = status;
        _ctx.handleSubmit();
      }
      }, _toDisplayString(status), 9, _hoisted_1))
    }), 128)),
    _createElementVNode("button", {
      class: "w-full p-2 hover:bg-gray-50",
      onClick: _cache[0] || (_cache[0] = () => {
        _ctx.query.status = null;
        _ctx.handleSubmit();
      })
    }, " Semua ")
  ]))
}