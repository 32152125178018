
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PaginationFooter',
  props: {
    page: Number,
    maxPage: Number,
  },
  data() {
    return {
      pageNum: 0,
      maxPageNum: 0,
      showPage: [] as Array<number>,
    };
  },
  mounted() {
    this.pageNum = parseInt(this.page?.toString() || '1');
    this.maxPageNum = parseInt(this.maxPage?.toString() || '1');
    this.getRange();
  },
  methods: {
    // getRange() {
    //   if (this.pageNum-1 >= 1) this.showPage.push(this.pageNum-1);
    //   this.showPage.push(this.pageNum);
    //   if (this.pageNum+1 < this.maxPageNum) this.showPage.push(this.pageNum+1);
    //   while(this.showPage.length < 3 && this.showPage[this.showPage.length-1] < this.maxPageNum) {
    //     this.showPage.push(this.showPage[this.showPage.length-1] + 1);
    //   }
    //   this.showPage.reverse()
    //   while(this.showPage.length < 3 && this.showPage[this.showPage.length-1] > 1) {
    //     this.showPage.push(this.showPage[this.showPage.length-1] - 1);
    //   }
    //   this.showPage.reverse()
    // },
    getRange() {
      this.showPage = []; // Clear the existing showPage array

      // Special case when total pages are 3 or less
      if (this.maxPageNum <= 3) {
        for (let i = 1; i <= this.maxPageNum; i++) {
          this.showPage.push(i);
        }
        return;
      }

      // Standard case for more than 3 pages
      if (this.pageNum - 1 >= 1) this.showPage.push(this.pageNum - 1);
      this.showPage.push(this.pageNum);
      if (this.pageNum + 1 <= this.maxPageNum) this.showPage.push(this.pageNum + 1);

      while (this.showPage.length < 3 && this.showPage[0] > 1) {
        this.showPage.unshift(this.showPage[0] - 1); // Add pages to the beginning if possible
      }

      while (this.showPage.length < 3 && this.showPage[this.showPage.length - 1] < this.maxPageNum) {
        this.showPage.push(this.showPage[this.showPage.length - 1] + 1); // Add pages to the end if possible
      }
    },
    changePage(newPage: number) {
      this.$emit('changePage', newPage);
    },
  },
})
