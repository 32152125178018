import { createRouter, createWebHistory } from 'vue-router'

import DashboardPage from '../pages/Dashboard.vue'

import PropertyDetailPage from '../pages/PropertyDetail.vue'

export default createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', component: DashboardPage },
    { path: '/property/:id', component: PropertyDetailPage },
  ],
})