
import { defineComponent } from "vue"
import BottomSheet from './BottomSheet.vue'
import FilterForm from "./FilterForm.vue"
import StatusForm from "./StatusForm.vue"
import CategoryForm from "./CategoryForm.vue"
import CityForm from "./CityForm.vue"

export default defineComponent({
  name: 'FilterSection',
  components: {
    BottomSheet,
    FilterForm,
    StatusForm,
    CategoryForm,
    CityForm,
  },
  data() {
    return {
      isFilterOpen: false,
      isStatusOpen: false,
      isCategoryOpen: false,
      isCityOpen: false,
    }
  },
  methods: {
    loadData() {
      this.$emit('loadData');
    },
  },
})
