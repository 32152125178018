
import { defineComponent } from "vue";

export default defineComponent({
  name: 'DocumentTitle',
  props: [
    'title'
  ],
  watch: {
    title: {
      immediate: true,
      handler() {
        document.title = this.title;
      }
    }
  },
})
