import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex justify-center items-center" }
const _hoisted_2 = {
  key: 1,
  class: "mx-1 h-[40px] w-[40px] bg-gray-100 rounded-full shadow-lg text-gray-500 flex justify-center items-center"
}
const _hoisted_3 = {
  key: 2,
  class: "flex"
}
const _hoisted_4 = {
  key: 0,
  class: "mx-1 h-[40px] w-[40px] rounded-full shadow-lg flex justify-center items-center hover:bg-gray-100 duration-300"
}
const _hoisted_5 = {
  key: 0,
  class: "mx-1 h-[40px] w-[40px] rounded-full shadow-lg bg-red-500 text-white flex justify-center items-center hover:bg-red-600 duration-300"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = {
  key: 3,
  class: "flex"
}
const _hoisted_8 = {
  key: 0,
  class: "mx-1 h-[40px] w-[40px] rounded-full shadow-lg flex justify-center items-center hover:bg-gray-100 duration-300"
}
const _hoisted_9 = {
  key: 5,
  class: "mx-1 h-[40px] w-[40px] rounded-full shadow-lg bg-gray-100 text-gray-500 flex justify-center items-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.pageNum > 1)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "mx-1 h-[40px] w-[40px] rounded-full shadow-lg text-red-500 flex justify-center items-center hover:bg-gray-100 duration-300",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changePage(_ctx.pageNum-1)))
        }, _toDisplayString("<")))
      : (_openBlock(), _createElementBlock("button", _hoisted_2, _toDisplayString("<"))),
    (_ctx.pageNum-1 > 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("button", {
            class: "mx-1 h-[40px] w-[40px] rounded-full shadow-lg flex justify-center items-center hover:bg-gray-100 duration-300",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changePage(1)))
          }, " 1 "),
          (_ctx.pageNum-2 > 1)
            ? (_openBlock(), _createElementBlock("button", _hoisted_4, " ... "))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.showPage, (i) => {
      return (_openBlock(), _createElementBlock("div", { key: i }, [
        (i == _ctx.pageNum)
          ? (_openBlock(), _createElementBlock("button", _hoisted_5, _toDisplayString(i), 1))
          : (_openBlock(), _createElementBlock("button", {
              key: 1,
              class: "mx-1 h-[40px] w-[40px] rounded-full shadow-lg flex justify-center items-center hover:bg-gray-100 duration-300",
              onClick: ($event: any) => (_ctx.changePage(i))
            }, _toDisplayString(i), 9, _hoisted_6))
      ]))
    }), 128)),
    (_ctx.pageNum+1 < _ctx.maxPageNum)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          (_ctx.pageNum+2 < _ctx.maxPageNum)
            ? (_openBlock(), _createElementBlock("button", _hoisted_8, " ... "))
            : _createCommentVNode("", true),
          _createElementVNode("button", {
            class: "mx-1 h-[40px] w-[40px] rounded-full shadow-lg flex justify-center items-center hover:bg-gray-100 duration-300",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changePage(_ctx.maxPageNum)))
          }, _toDisplayString(_ctx.maxPageNum), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.pageNum < _ctx.maxPageNum)
      ? (_openBlock(), _createElementBlock("button", {
          key: 4,
          class: "mx-1 h-[40px] w-[40px] rounded-full shadow-lg text-red-500 flex justify-center items-center hover:bg-gray-100 duration-300",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.changePage(_ctx.pageNum+1)))
        }, _toDisplayString(">")))
      : (_openBlock(), _createElementBlock("button", _hoisted_9, _toDisplayString(">")))
  ]))
}