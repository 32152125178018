import { createStore } from 'vuex';

export default createStore({
  state: {
    filterQuery: {
      minHarga: null,
      maxHarga: null,
      minHargaPerM2: null,
      maxHargaPerM2: null,
      minHargaPerTahun: null,
      maxHargaPerTahun: null,
      luasBangunan: null,
      luasTanah: null,
      kamarTidur: null,
      kamarMandi: null,
      kamarTidurPembantu: null,
      kamarMandiPembantu: null,
    }
  },
  mutations: {
    setFilterQuery (state, payload) {
      state.filterQuery = payload;
    },
  },
  actions: {
    updateFilterQuery ({ commit }, payload) {
      commit('setFilterQuery', payload);
    },
  },
  modules: {
    // your modules here
  }
});
