import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-2" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "flex justify-between" }
const _hoisted_4 = { class: "text-gray-500 text-ellipsis" }
const _hoisted_5 = { class: "mt-4" }
const _hoisted_6 = {
  key: 1,
  class: "flex justify-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!
  const _component_PropertiesGrid = _resolveComponent("PropertiesGrid")!
  const _component_PropertiesList = _resolveComponent("PropertiesList")!
  const _component_Pagination = _resolveComponent("Pagination")!
  const _component_pulse_loader = _resolveComponent("pulse-loader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.properties)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("p", _hoisted_4, "Menampilkan " + _toDisplayString(_ctx.properties.length) + " properti di \"" + _toDisplayString(_ctx.scope) + "\"", 1),
            _createElementVNode("button", {
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeViewMode && _ctx.changeViewMode(...args))),
              class: "border-2 w-8 rounded-md text-gray-500 hover:bg-gray-100 active:bg-gray-300 duration-300"
            }, [
              (_ctx.viewMode === 'list')
                ? (_openBlock(), _createBlock(_component_fa_icon, {
                    key: 0,
                    icon: "fa-solid fa-grip-vertical"
                  }))
                : _createCommentVNode("", true),
              (_ctx.viewMode === 'grid')
                ? (_openBlock(), _createBlock(_component_fa_icon, {
                    key: 1,
                    icon: "fa-solid fa-list"
                  }))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            (_ctx.viewMode === 'grid')
              ? (_openBlock(), _createBlock(_component_PropertiesGrid, {
                  key: 0,
                  properties: _ctx.properties
                }, null, 8, ["properties"]))
              : _createCommentVNode("", true),
            (_ctx.viewMode === 'list')
              ? (_openBlock(), _createBlock(_component_PropertiesList, {
                  key: 1,
                  properties: _ctx.properties
                }, null, 8, ["properties"]))
              : _createCommentVNode("", true),
            _createVNode(_component_Pagination, {
              class: "mt-10",
              maxPage: _ctx.maxPage,
              page: _ctx.page,
              onChangePage: _ctx.changePage
            }, null, 8, ["maxPage", "page", "onChangePage"])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_pulse_loader, {
            loading: true,
            color: _ctx.color,
            size: _ctx.size
          }, null, 8, ["color", "size"])
        ]))
  ]))
}